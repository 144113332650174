<template>
	<transition name="fade">
		<div class="modal" v-if="show">
			<div class="modal__backdrop" @click="closeModal()" />

			<div class="modal__dialog">
				<div class="modal__header">
					<slot name="header" />
					<button type="button" class="modal__close" @click="closeModal()">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
							<path
								class="text-white fill-current"
								d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
							></path>
						</svg>
					</button>
				</div>

				<div class="modal__body">
					<slot name="body" />
				</div>

				<div class="modal__footer">
					<slot name="footer" />
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: "Modal",
		data() {
			return {
				show: false,
			};
		},
		methods: {
			closeModal() {
				this.show = false;
				document.querySelector("body").classList.remove("overflow-hidden");
			},
			openModal() {
				this.show = true;
				document.querySelector("body").classList.add("overflow-hidden");
			},
		},
	};
</script>

<style scoped>
	.modal {
		overflow-x: hidden;
		overflow-y: auto;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9999;
	}
	.modal__backdrop {
		background-color: rgba(0, 0, 0, 0.3);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}
	.modal__dialog {
		background-color: #000;
		position: relative;
		width: 600px;
		margin: 50px auto;
		display: flex;
		flex-direction: column;
		border-radius: 5px;
		z-index: 2;
	}
	@media screen and (max-width: 992px) {
		.modal__dialog {
			width: 90%;
		}
	}
	.modal__close {
		width: 30px;
		height: 30px;
	}
	.modal__header {
		padding: 20px 20px 10px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	.modal__body {
		padding: 10px 20px 10px;
		overflow: auto;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}
	.modal__footer {
		padding: 10px 20px 20px;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
